var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"primary-folder"},[(_vm.loading)?[_c('div',{staticClass:"primary-folder-header"},[_c('div',{staticClass:"primary-folder-header-tit"},[_c('span',{staticClass:"pos"}),_c('p',[_vm._v(_vm._s(_vm.book.bookName))])]),_c('div',{staticClass:"primary-folder-header-info"},[_c('span',{staticClass:"pos"}),_c('div',{staticClass:"info"},[_c('span',{staticClass:"publisher",domProps:{"innerHTML":_vm._s(_vm.book.publisher)}}),_c('div',{staticClass:"small-box"},[_c('span',{staticClass:"count"},[_vm._v(_vm._s(this.book.studyCount)+"人学习")]),_c('router-link',{staticClass:"opinion",attrs:{"to":{
                name: 'propose',
                query: { bid: _vm.bid, fid: _vm.fid, source: 2 },
              }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/op.png"),"alt":""}})]),_c('em',[_vm._v("意见反馈")])])],1)])]),_c('div',{staticClass:"primary-folder-header-img"},[_c('img',{attrs:{"src":_vm.cover,"alt":""}})])]),(!_vm.resListState)?_c('div',[(_vm.book.slogan)?[_c('p',{staticClass:"mxy-noDown"},[_vm._v(_vm._s(_vm.book.slogan))]),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("目录名称："+_vm._s(_vm.folder.folderName))])]:_vm._e()],2):_vm._e(),(_vm.listLoading)?[_c('div',{staticClass:"primary-folder-list"},[_c('div',{staticClass:"primary-folder-list-tit"},[(
              _vm.folder.bookSeries == '427400973770559488' &&
              _vm.folder.folderTitle != ''
            )?_c('span',[_vm._v(" "+_vm._s(_vm.folder.folderTitle)+" ")]):_c('span',[_vm._v("拓展资源")]),(_vm.folder.downType == 1 && _vm.folder.filePath != '')?[_c('label',{staticClass:"all",on:{"click":function($event){return _vm.allDown(_vm.folder.filePath)}}},[_vm._v("全部资源下载")])]:_vm._e()],2),_c('div',{staticClass:"primary-folder-list-data"},[(_vm.resListState)?[_vm._l((_vm.resList),function(item){return [(
                  _vm.folder.bookSeries == '427400973770559488' &&
                  item.hasFollowRead == 1
                )?_c('router-link',{key:item.rid,attrs:{"to":{
                  name: 'recorder',
                  query: {
                    bid: _vm.folder.bid,
                    rid: item.rid,
                    memory: item.hasWordMemory,
                    zoom: item.followIconName,
                    rname: item.fileName,
                    bname: _vm.folder.bookName,
                  },
                }}},[(item.fileExtendName == '.mp3')?[_c('img',{staticClass:"text",attrs:{"src":require("../assets/images/media.png"),"alt":""}})]:[_c('img',{staticClass:"text",attrs:{"src":require("../assets/images/text.png")}})],_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(item.fileName))])])],2):_c('router-link',{key:item.rid,attrs:{"to":{
                  name: 'resource',
                  query: {
                    bid: item.bid,
                    fid: item.fid,
                    rid: item.rid,
                    // rname: item.fileName,
                    // bname: folder.bookName,
                  },
                }}},[(item.fileExtendName == '.mp3')?[_c('img',{staticClass:"text",attrs:{"src":require("../assets/images/media.png"),"alt":""}})]:[_c('img',{staticClass:"text",attrs:{"src":require("../assets/images/text.png")}})],_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(item.fileName))])])],2)]})]:[_c('div',{staticClass:"nolist"},[_vm._v(_vm._s(_vm.folder.folderName))]),_c('div',{staticClass:"nolist"},[_vm._v("没找到其他资源")])]],2)])]:[_c('div',{staticClass:"primary-folder-no"},[_c('van-loading',[_vm._v(_vm._s(_vm.listLoadingText))])],1)]]:[(_vm.loadingExce)?[_c('div',{staticClass:"primary-nothing"},[_c('img',{staticClass:"cover",attrs:{"src":require("../assets/images/nothing.png"),"alt":""}}),_c('p',{staticClass:"title"},[_vm._v("您查找的内容不存在")]),_c('router-link',{staticClass:"btn",attrs:{"to":{
            name: 'propose',
            query: { bid: _vm.bid, fid: _vm.fid, source: _vm.source },
          }}},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/ope.png"),"alt":""}}),_vm._v(" 我要反馈 ")])],1)]:[_c('div',{staticClass:"primary-folder-no"},[_c('van-loading',[_vm._v(_vm._s(_vm.loadingText))])],1)]]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }