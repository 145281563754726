<template>
  <div class="primary-folder">
    <template v-if="loading">
      <div class="primary-folder-header">
        <div class="primary-folder-header-tit">
          <span class="pos"></span>
          <p>{{ book.bookName }}</p>
          <!-- style="white-space: pre-wrap" -->
        </div>
        <div class="primary-folder-header-info">
          <span class="pos"></span>
          <div class="info">
            <span class="publisher" v-html="book.publisher"></span>
            <div class="small-box">
              <span class="count">{{ this.book.studyCount }}人学习</span>
              <router-link
                :to="{
                  name: 'propose',
                  query: { bid: bid, fid: fid, source: 2 },
                }"
                class="opinion"
              >
                <span class="icon"
                  ><img src="@/assets/images/op.png" alt=""
                /></span>
                <em>意见反馈</em>
              </router-link>
            </div>
          </div>
        </div>
        <div class="primary-folder-header-img">
          <img :src="cover" alt="" />
        </div>
      </div>
      <div v-if="!resListState">
        <template v-if="book.slogan">
          <p class="mxy-noDown">{{ book.slogan }}</p>
          <p style="text-align: center">目录名称：{{ folder.folderName }}</p>
        </template>
        <!-- <em v-else>资源还在路上！</em> -->
      </div>
      <template v-if="listLoading">
        <div class="primary-folder-list">
          <div class="primary-folder-list-tit">
            <span
              v-if="
                folder.bookSeries == '427400973770559488' &&
                folder.folderTitle != ''
              "
            >
              {{ folder.folderTitle }}
            </span>
            <span v-else>拓展资源</span>
            <template v-if="folder.downType == 1 && folder.filePath != ''">
              <label class="all" @click="allDown(folder.filePath)"
                >全部资源下载</label
              >
            </template>
          </div>
          <div class="primary-folder-list-data">
            <template v-if="resListState">
              <template v-for="item in resList">
                <router-link
                  v-if="
                    folder.bookSeries == '427400973770559488' &&
                    item.hasFollowRead == 1
                  "
                  :key="item.rid"
                  :to="{
                    name: 'recorder',
                    query: {
                      bid: folder.bid,
                      rid: item.rid,
                      memory: item.hasWordMemory,
                      zoom: item.followIconName,
                      rname: item.fileName,
                      bname: folder.bookName,
                    },
                  }"
                >
                  <template v-if="item.fileExtendName == '.mp3'">
                    <img class="text" src="../assets/images/media.png" alt="" />
                  </template>
                  <template v-else
                    ><img class="text" src="../assets/images/text.png"
                  /></template>
                  <div class="title">
                    <p>{{ item.fileName }}</p>
                  </div>
                </router-link>
                <router-link
                  v-else
                  :key="item.rid"
                  :to="{
                    name: 'resource',
                    query: {
                      bid: item.bid,
                      fid: item.fid,
                      rid: item.rid,
                      // rname: item.fileName,
                      // bname: folder.bookName,
                    },
                  }"
                >
                  <template v-if="item.fileExtendName == '.mp3'">
                    <img class="text" src="../assets/images/media.png" alt="" />
                  </template>
                  <template v-else
                    ><img class="text" src="../assets/images/text.png"
                  /></template>
                  <div class="title">
                    <p>{{ item.fileName }}</p>
                  </div>
                </router-link>
              </template>
            </template>
            <template v-else>
              <div class="nolist">{{ folder.folderName }}</div>
              <div class="nolist">没找到其他资源</div>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="primary-folder-no">
          <van-loading>{{ listLoadingText }}</van-loading>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="loadingExce">
        <div class="primary-nothing">
          <img class="cover" src="../assets/images/nothing.png" alt="" />
          <p class="title">您查找的内容不存在</p>
          <router-link
            :to="{
              name: 'propose',
              query: { bid: bid, fid: fid, source: source },
            }"
            class="btn"
          >
            <img class="icon" src="../assets/images/ope.png" alt="" />
            我要反馈
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="primary-folder-no">
          <van-loading>{{ loadingText }}</van-loading>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import qs from "qs";
import { Dialog } from "vant";

export default {
  name: "folder",
  data() {
    return {
      loading: false,
      loadingText: "解析中...",
      listLoading: false,
      listLoadingText: "资源列表加载中...",
      loadingExce: false,
      bid: this.$route.query.bid,
      fid: this.$route.query.fid,
      folder: "",
      resList: "",
      resListState: false,
      book: "",
      cover: this.$store.state.iCdn + "2021XXNewBook/images/nobook.jpg",
      source: 2,
    };
  },
  watch: {},
  mounted() {
    //this.bid = '437614389994983424';
    //this.fid = '451757849152851968';

    //this.bid = '443004666419089408';
    //this.fid = '443004761524932608';
    //console.log(this.resList[0].filePath)
    //判断参数是否有值
    if (
      this.bid != undefined &&
      this.bid != "" &&
      this.fid != undefined &&
      this.fid != ""
    ) {
      this.getBook();
      this.getFolder();
    } else {
      this.loading = false;
      this.loadingText = "解析URL出错啦...";
      this.loadingExce = true;
    }
  },
  methods: {
    //全部资源下载记录
    allDown(e) {
      if (e != null || e != "") {
        let params = {
          bid: this.bid,
          fid: this.fid,
        };

        this.$api.mary.DownFolderPackge(params).then((res) => {
          let { tag, message, data } = res;
          if (tag == 1) {
            window.open(e, "_blank");
          } else {
            Dialog.alert({
              message: message,
            });
          }
        });
      }
    },

    //截取出版社
    publisher(e) {
      let s = this.book.publisher.replace(new RegExp("\\/", "g"), "</br>");
      return s;
    },

    //获取图书
    getBook() {
      this.loading = false;
      let params = {
        bid: this.bid,
      };
      this.$api.mary.GetBookInfo(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
          this.book = data;
          this.loading = true;
          if (this.book.coverImg != "") {
            this.cover = this.book.coverImg;
          }
          if (
            this.book.publisher != "" &&
            this.book.publisher.indexOf("/") > 0
          ) {
            this.book.publisher = this.book.publisher.replace(
              new RegExp("\\/", "g"),
              "</br>"
            );
          }
          sessionStorage.clear("bookinfo");
          sessionStorage.setItem("bookinfo", qs.stringify(data));
          //sessionStorage.setItem('book',Data);
          //let b = sessionStorage.getItem('book');
        } else {
          this.loadingExce = true;
          this.loading = false;
          this.loadingText = "图书走丢了...";
        }
        //console.log(res);
      });
    },

    //获取目录
    async getFolder() {
      this.listLoading = false;
      //const folders = sessionStorage.getItem(this.bid)

      let params = {
        bid: this.bid,
        fid: this.fid,
      };
      this.folder = await this.$api.mary.GetFolderInfo(params);
      //console.log(info)
      //sessionStorage.setItem(info.data.bid, qs.stringify(info))
      //console.log(info.)

      //操作数据
      if (this.folder.tag == 1) {
        this.folder = this.folder.data;

        //如何目录变资源直接跳转资源页面
        if (this.folder.folderType == 2) {
          await this.$router.push({
            name: "resource",
            query: {
              rid: this.folder.resId2,
              bid: this.folder.bid,
              fid: this.folder.fid,
            },
          });
        }

        if (this.folder.resList && this.folder.resList.length > 0) {
          this.resListState = true;
          this.resList = this.folder.resList;
        }
        this.listLoading = true;
        document.title = this.folder.bookName + "-" + this.folder.folderName;
      } else {
        this.loadingExce = true;
        this.listLoading = false;
        this.listLoadingText = "列表资源走丢了...";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mxy-noDown {
  font-size: 0.45rem;
}
</style>
